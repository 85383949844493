<template>
  <div id="company-search">
    <div class="crumbs">
      <router-link :to="indexPath">首页</router-link>
      <label>></label>
      <a>集成公司列表</a>
    </div>
    <div class="search">
      <div>
        <label>公司名称</label>
        <el-input v-model="search.name"></el-input>
        <label>公司资质</label>
        <el-input v-model="search.aptitude"></el-input>
      </div>
      <div>
        <button @click="getData()">搜索</button>
      </div>
    </div>
    <el-table :data="tableData" v-loading="loading" highlight-current-row>
      <el-table-column label="公司名称" width="210">
        <template slot-scope="scope">
          <div class="table-merge">
            <el-image :src="scope.row.logo" fit="cover"></el-image>
            <label>{{ scope.row.name }}</label>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="aptitude1" label="公司资质"></el-table-column>
      <el-table-column prop="bidAmount" label="投标数量"></el-table-column>
      <el-table-column prop="salse" label="年销售额"></el-table-column>
    </el-table>
    <div class="page">
      <el-pagination
        v-if="page.pages > 1"
        background
        layout="prev, pager, next"
        :page-size="page.pageSize"
        :total="page.total"
        @current-change="getData"
        :current-page.sync="page.pageNum"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "company-search",
  data() {
    return {
      loading: false,
      search: {
        name: "",
        aptitude: "",
      },
      tableData: [],
      page: {
        pageNum: 0,
        pageSize: 6,
        total: 0,
        pages: 0,
      },
    };
  },
  computed: {
    indexPath() {
      const type = localStorage.user && JSON.parse(localStorage.user)?.type;
      const result = {
        1: "/control/companyb",
        2: "/control/companyc",
      };
      return type ? result[type] : "";
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData(pageNum = 1) {
      this.loading = true;
      this.$axios
        .get("/member/integrationMember", {
          params: {
            name: this.search.name,
            aptitude: this.search.aptitude,
            pageNum,
          },
        })
        .then((res) => {
          if (res.state === 200) {
            const { list, pageNum, total, pages } = res.data;
            this.tableData = list.reduce((acc, cur) => {
              acc.push(
                Object.assign(cur, {
                  aptitude1: cur.aptitude.join("，"),
                })
              );
              return acc;
            }, []);
            this.page.pageNum = pageNum;
            this.page.total = total;
            this.page.pages = pages;
            this.loading = false;
          } else {
            this.$message.error(res.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#company-search {
  display: flex;
  flex-direction: column;
  margin: 0 0 114px;
}
</style>
